import { useCallback } from 'react'
import { APPLY_FDR_URL } from '../constants'
import { useRouter } from 'next/router'
import { getCookies } from '../helpers'

export const useNavigateToApply = (params = {}) => {
  const router = useRouter()

  const navigateToApply = useCallback(() => {
    let baseUrl = APPLY_FDR_URL()
    if (router.pathname.includes('espanol'))
      baseUrl = APPLY_FDR_URL() + '/espanol'

    const newUrl = new URL(baseUrl)
    const { eh_visit_id, leadId } = getCookies()
    newUrl.searchParams.append('from', 'fdr')
    if (leadId) newUrl.searchParams.append('leadId', leadId)
    if (eh_visit_id) newUrl.searchParams.append('visit_id', eh_visit_id)
    for (const key in params) {
      if (params[key]) newUrl.searchParams.append(key, params[key])
    }

    const fullUrl = newUrl.toString()
    router.push(fullUrl)
  }, [params, router])

  return navigateToApply
}
